<template>
  <div class="my-input-wz-quantity">
    <fawesome-pro variant="fal" icon="minus" class="cursor-pointer mx-2" @click.native="$emit('onClickMinus')" />
    <b-input
      v-model="model"
      type="number"
      :min="min"
      :max="max"
      class="bg-white"
      no-wheel
      readonly
     />
    <fawesome-pro variant="fal" icon="plus" class="cursor-pointer mx-2" @click.native="$emit('onClickPlus')" />
  </div>
</template>

<script>
export default {
  name: "MyInputFormQuantity",
  props: ["value", "min", "max"],
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      } 
    }
  },
}
</script>

<style scoped>
  .my-input-wz-quantity {
    border: 1px solid #C2C2C2;
    display: flex;
    align-items: center;
  }
  .my-input-wz-quantity input {
    text-align: center;
    border: none;
  }
</style>